import axios from 'axios';
import Vehicle from '../Models/Vehicle';


class VehicleService {


  baseUrl: string;
  httpClient: any;

  constructor(httpClient: any) {
    this.baseUrl = process.env.REACT_APP_API_URL!;
    this.httpClient = httpClient;
  }

  async getVehicles() {
    try {
      const response = await axios.get(`${this.baseUrl}/api/vehicles/GetAllVehicles`);
      return response.data;
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      return [];
    }
  };

  // Method to get a vehicle by contractor id
  async getVehiclesByContractorId(contractorId: number): Promise<Vehicle[]> {
    try {
      const response = await axios.get(`${this.baseUrl}/vehicles/getvehiclesbycontractorid/${contractorId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching vehicles by contractorId:", error);
      return [];
    }
  }
  
  //save project vehicles in list from batch
  async saveListOfVehicles(vehicles: Vehicle[], projectId: number): Promise<Vehicle[]> {
    try {
      const response = await axios.post(`${this.baseUrl}/vehicles/batch?projectId=${projectId}`, vehicles);
      return response.data;
    } catch (error) {
      console.error("Error creating project:", error);
      return [];
    }
  }

  async createVehicle(vehicle: Vehicle): Promise<Vehicle[]> {
    try {
      const response = await axios.post(`${this.baseUrl}/vehicles/createvehicle`, vehicle);
      return response.data;
    } catch (error) {
      console.error("Error creating project:", error);
      return [];
    }
  }

  async getVehiclesByProjectId(projectId: number): Promise<Vehicle[]> {
    try {
      const response = await axios.get<Vehicle[]>(`${this.baseUrl}/vehicles/getvehiclebyprojectid/${projectId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      return [];
    }
  }

  // Method to update an existing vehicle
  async updateVehicles(vehicles: Vehicle[]): Promise<Vehicle[]> {
    var response = await this.httpClient.put(`${this.baseUrl}/vehicles/updatevehicles`, vehicles);
    return response.data;
  } catch(error: any) {
    console.error("Error creating project:", error);
    return [];
  }

  async deleteVehicle(vehicleId: number): Promise<void> {
    await this.httpClient.delete(`${this.baseUrl}/vehicles/${vehicleId}`);
  }


  //method to update existing vehicle status
  async updateVehicleStatus(vehicleId: number, contractorId: number, status: string): Promise<Vehicle[]> {
    try {
      const response = await axios.put(`${this.baseUrl}/vehicles/updatestatus/${vehicleId}/${contractorId}/${status}`);
      return response.data;
    } catch (error) {
      console.error("Error updating vehicle status:", error);
      return [];
    }
  }

  //method to get vehicles by contractorId and status
  async getVehiclesByContractorIdAndStatus(contractorId: number, status: string): Promise<Vehicle[]> {
    try {
      const encodedStatus = encodeURIComponent(status);
      const response = await axios.get(`${this.baseUrl}/vehicles/getvehiclesbycontractorstatus/${contractorId}/${encodedStatus}`);
      return response.data;
    } catch (error) {
      console.error("Error getting vehicles by contractorId and status:", error);
      return [];
    }
  }

  //get vehicles with a certain status
  async getVehiclesByStatus(status: string): Promise<Vehicle[]> {
    try {
      const encodedStatus = encodeURIComponent(status);
      const response = await axios.get(`${this.baseUrl}/vehicles/getvehiclesbystatus/${encodedStatus}`);
      return response.data;
    } catch (error) {
      console.error("Error getting vehicles by status:", error);
      return [];
    }
  }

}
export default VehicleService;