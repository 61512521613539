// ProtectedRoute.tsx
import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

interface ProtectedRouteProps {
    roles: string[];
    element: ReactElement;
}
const baseUrl = process.env.REACT_APP_BASE_URL;

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ roles, element }) => {
    const { isAuthenticated, user } = useAuth0();
    const namespace = `${baseUrl}/roles`; // Using the correct namespace
    const userRoles = user ? user[namespace] || [] : [];
    const isAuthorized = isAuthenticated && roles.some(role => userRoles.includes(role));

    return isAuthorized ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
