import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/Header';
import Content from './components/ContentRoutes';
import './App.css';
import Appbar from './components/AppBar';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, createTheme } from '@mui/material';
import { Css } from '@mui/icons-material';

// Sophisticated and unique theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#3F51B5', // A deep, rich red
    },
    secondary: {
      main: '#D32F2F', // A muted, sophisticated blue
    },
    error: {
      main: '#E57373', // A softer red for errors
    },
    warning: {
      main: '#FFC107', // A golden hue for warnings
    },
    success: {
      main: '#388E3C', // A deep, forest green for success
    },
    background: {
      default: '#F0F0F0', // A light grey for a clean and modern background
    },
    text: {
      primary: '#212121', // A dark shade of black for high readability
      secondary: '#757575', // A medium shade of black for secondary text
    },
  },
  typography: {
    // You can set a global font configuration here
    // For example:
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',

  },
  components: {
    // Override styles for all instances of this component
    MuiButton: {
      styleOverrides: {
        root: { // This targets the root element of the component
          fontWeight: 'bold', // Make text bold
          // You can also add other style overrides here
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          // Ensure cells are top-aligned
          verticalAlign: 'top',
        },
      },
    },
  },

});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Appbar />
        <Content />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
