// PostInstallQuestions.tsx
import React from 'react';
import { Box, Grid, Typography, RadioGroup, FormControlLabel, Radio, Paper, FormControl } from '@mui/material';

interface PostInstallQuestionsProps {
  questions: { id: string; question: string }[];
  answers: { [key: string]: string };
  onAnswerChange: (questionId: string, answer: string) => void;
}

const PostInstallQuestions: React.FC<PostInstallQuestionsProps> = ({ questions, answers, onAnswerChange }) => {
  return (
    <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ mt: 2, mb: 2, fontWeight: 'medium' }}>
        Post-Install Questions
      </Typography>
      <FormControl component="fieldset" fullWidth variant="standard">
        {questions.map((question) => (
          <Grid container spacing={2} key={question.id} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom>{question.question}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <RadioGroup
                row
                name={question.id}
                value={answers[question.id] || 'no'}
                onChange={(e) => onAnswerChange(question.id, e.target.value)}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
                <FormControlLabel value="na" control={<Radio />} label="N/A" />
              </RadioGroup>
            </Grid>
          </Grid>
        ))}
      </FormControl>
    </Paper>
  );
};

export default PostInstallQuestions;
