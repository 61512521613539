import React from 'react';
import { Grid, Button } from '@mui/material';

interface VehicleTypeSelectorProps {
    vehicleTypes: string[];
    selectedVehicleType: string;
    onVehicleTypeChange: (type: string) => void;
}

const VehicleTypeSelector: React.FC<VehicleTypeSelectorProps> = ({ vehicleTypes, selectedVehicleType, onVehicleTypeChange }) => {
    return (
        <Grid container spacing={2}>
            {vehicleTypes.map((type, index) => (
                <Grid item key={index}>
                    <Button
                        variant={selectedVehicleType === type ? "contained" : "outlined"}
                        onClick={() => onVehicleTypeChange(type)}
                    >
                        {type}
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
};

export default VehicleTypeSelector;
