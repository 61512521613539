// src/utils/indexedDB.ts
import { openDB, IDBPDatabase } from 'idb';

const DB_NAME = 'inspectionFormDB';
const STORE_NAME = 'inspectionFormStore';
const DB_VERSION = 1;

let db: IDBPDatabase | null = null;

const getDB = async () => {
    if (!db) {
        db = await openDB(DB_NAME, DB_VERSION, {
            upgrade(db) {
                db.createObjectStore(STORE_NAME);
            },
        });
    }
    return db;
};

export const saveFormDataToIndexedDB = async (key: string, data: any) => {
    const db = await getDB();
    await db.put(STORE_NAME, data, key);
};

export const loadFormDataFromIndexedDB = async (key: string): Promise<any | null> => {
    const db = await getDB();
    return await db.get(STORE_NAME, key);
};

export const clearIndexedDB = async (key: string) => {
    const db = await getDB();
    await db.delete(STORE_NAME, key);
};
