class EquipmentService {
    baseUrl: string;
    httpClient: any;

    constructor(httpClient: any) {
        this.baseUrl = process.env.REACT_APP_API_URL!;
        this.httpClient = httpClient;
    }

    async getEquipmentByOrganizationId(orgId: number) {
        try {
            const response = await this.httpClient.get(`${this.baseUrl}/EquipmentType/getEquipmentByOrganization/${orgId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching equipment for organization ${orgId}:`, error);
            throw error;
        }
    }

    async getEquipmentById(equipmentId: number) {
        try {
            const response = await this.httpClient.get(`${this.baseUrl}/EquipmentType/getEquipmentById/${equipmentId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching equipment ${equipmentId}:`, error);
            throw error;
        }
    }

    //get equipment by vehicle id
    async GetVehicleEquipmentByVehicleId(vehicleId: number) {
        try {
            const response = await this.httpClient.get(`${this.baseUrl}/VehicleEquipment/getVehicleEquipmentByVehicleId/${vehicleId}`);
            return response.data;
        } catch (error) {
            console.error(`Error fetching equipment for vehicle ${vehicleId}:`, error);
            throw error;
        }
    }
}

export default EquipmentService;