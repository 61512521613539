import React from 'react';
import { Button, List, ListItem, ListItemAvatar, Avatar, ListItemText, Card, IconButton, ListItemSecondaryAction } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import EnhancedFile from '../../Models/EnhancedFile';
import DownloadIcon from '@mui/icons-material/Download';

interface FileUploadProps {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilePreview: (file: any) => void;
  files: EnhancedFile[];
}

const FileUploadComponent: React.FC<FileUploadProps> = ({ onFileChange, onFilePreview, files }) => {
  return (
    <div>
      {/* File Upload and List */}
      <Card variant="outlined" sx={{ p: 2, mb: 3 }}>
        <Button variant="contained" color="primary" startIcon={<CloudUploadIcon />} component="label">
          Upload Files
          <input type="file" multiple hidden onChange={onFileChange} />
        </Button>

        <List sx={{ mt: 2, maxHeight: '300px', overflowY: 'auto' }}>
          {files.map(file => (
            <ListItem key={file.s3Key} button onClick={() => onFilePreview(file)}>
              <ListItemAvatar>
                <Avatar>
                  <InsertDriveFileIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={file.fileName} secondary={`${(file.size / 1024).toFixed(2)} KB`} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="download" href={file.presignedUrl || ''} target="_blank">
                  <DownloadIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Card>
    </div>
  );
};

export default FileUploadComponent;
