const generateUniqueProjectNumber = () => {
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
    const date = String(currentDate.getDate()).padStart(2, '0');
    const year = currentDate.getFullYear();
    
    const randomNum = Math.floor(Math.random() * 10000).toString().padStart(4, '0'); // Generates a random number between 0 and 9999, then pads it to ensure 4 digits
    
    return `${month}${date}${year}-${randomNum}`;
  };
  
  export { generateUniqueProjectNumber };