import AWS from 'aws-sdk';

// Configuration for AWS SDK
const config = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
  region: process.env.REACT_APP_AWS_REGION!,
};

// Update AWS SDK configuration
AWS.config.update(config);

// Create CloudWatchLogs instance
const cloudwatchlogs = new AWS.CloudWatchLogs();

// Retrieve log group and log stream names from environment variables
const logGroupName = process.env.REACT_APP_LOG_GROUP_NAME!;
const logStreamName = process.env.REACT_APP_LOG_STREAM_NAME!;

let sequenceToken: string | undefined = undefined;

// Determine if running on localhost
const isLocalhost = window.location.hostname === 'localhost';

// Function to initialize log group and log stream
const initLogGroupAndStream = async () => {
  if (isLocalhost) {
    console.log('Localhost - Skipping AWS log group and stream initialization');
    return;
  }

  try {
    // Create log group if it doesn't exist
    await cloudwatchlogs.createLogGroup({ logGroupName }).promise();
  } catch (err: any) {
    if (err.code !== 'ResourceAlreadyExistsException') {
      console.error('Error creating log group:', err);
    }
  }

  try {
    // Create log stream if it doesn't exist
    await cloudwatchlogs.createLogStream({ logGroupName, logStreamName }).promise();
  } catch (err: any) {
    if (err.code !== 'ResourceAlreadyExistsException') {
      console.error('Error creating log stream:', err);
    }
  }
};

// Function to log messages to CloudWatch
const logMessage = async (message: any, type: 'INFO' | 'ERROR') => {
  if (isLocalhost) {
    console.log(`Localhost - Skipping AWS log ${type} message:`, message);
    return;
  }

  const params: AWS.CloudWatchLogs.Types.PutLogEventsRequest = {
    logEvents: [
      {
        message: JSON.stringify({ type, ...message }),
        timestamp: new Date().getTime(),
      },
    ],
    logGroupName,
    logStreamName,
    sequenceToken,
  };

  try {
    const data = await cloudwatchlogs.putLogEvents(params).promise();
    sequenceToken = data.nextSequenceToken ?? undefined;
  } catch (err) {
    console.error('Error sending logs:', err);
  }
};

const logInfo = (message: any) => logMessage(message, 'INFO');
const logError = (message: any) => logMessage(message, 'ERROR');

export { initLogGroupAndStream, logInfo, logError };

export default cloudwatchlogs;
