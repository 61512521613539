class ContractorService {
    baseUrl: string;
    httpClient: any;

    constructor(httpClient: any) {
        this.baseUrl = process.env.REACT_APP_API_URL!;
        this.httpClient = httpClient;
    }

    //get all contractors
    async getAllContractors() {
        try {
            const response = await this.httpClient.get(`${this.baseUrl}/Contractor/getallcontractors`);
            return response.data;
        } catch (error) {
            console.error("Error fetching contractors:", error);
            throw error;
        }
    }

    // generate exhibitAs for each contractor
    async generateExhibitA(projectId: number, contractorIds: number[]) {
        try {
            const response = await this.httpClient.post(`${this.baseUrl}/Contractor/generateExhibitAs`, { projectId, contractorIds });
            return response.data;
        } catch (error) {
            console.error("Error fetching contractors:", error);
            throw error;
        }
    }

    //generate exhibitA for SiteLeads
    async generateExhibitASiteLeads(projectId: number, contractorIds: number[]) {
        try {
            const response = await this.httpClient.post(`${this.baseUrl}/Contractor/generateExhibitASiteLeads`, { projectId, contractorIds });
            return response.data;
        } catch (error) {
            console.error("Error fetching contractors:", error);
            throw error;
        }
    }

    // get exhibitA by contractorId
    async getExhibitAsByContractor(contractorId: number) {
        try {
            const response = await this.httpClient.get(`${this.baseUrl}/Contractor/getexhibitabycontractor/${contractorId}`);
            return response.data;
        } catch (error) {
            console.error("Error fetching contractors:", error);
            throw error;
        }
    }

    // get contractor by contractorId
    async getContractorById(contractorId: number) {
        try {
            const response = await this.httpClient.get(`${this.baseUrl}/Contractor/getcontractorbyid/${contractorId}`);
            return response.data;
        } catch (error) {
            console.error("Error fetching contractors:", error);
            throw error;
        }
    }
    //get contractor by email
    async getContractorByEmail(email: string) {
        try {
            const response = await this.httpClient.get(`${this.baseUrl}/Contractor/getcontractorbyemail/${email}`);
            return response.data;
        } catch (error) {
            console.error("Error fetching contractors:", error);
            throw error;
        }
    }

    //save contractor signature
    async saveContractorSignature(exhibitAId: number, signature: string) {
        try {
            const payload = {
                exhibitAId: exhibitAId,
                signature: signature
            };
            const response = await this.httpClient.put(`${this.baseUrl}/Contractor/savecontractorsignature`, payload);
            return response.data;
        } catch (error) {
            console.error("Error saving contractor signature:", error);
            throw error;
        }
    }
}

export default ContractorService;