import React from 'react';
import { Typography, Button, TextareaAutosize, List, ListItem, ListItemText, AccordionDetails, AccordionSummary, Accordion, styled, Grid, TextField } from '@mui/material';
import Quote from '../../Models/Quote';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NoteTextArea = styled(TextareaAutosize)(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: '1rem',
    borderRadius: '4px',
    border: '1px solid #e0e0e0',
}));

interface NotesSectionProps {
    currentNote: string;
    setCurrentNote: React.Dispatch<React.SetStateAction<string>>;
    handleAddNote: () => void;
    selectedQuote: Quote | null;
}

const NotesSection: React.FC<NotesSectionProps> = ({ currentNote, setCurrentNote, handleAddNote, selectedQuote }) => {
    return (
        <div>

  <Typography fontWeight="bold" variant="h6" gutterBottom>
    Notes
  </Typography>
  <Grid container spacing={2}>
    <Grid item xs={12} sm>
      <TextField
        fullWidth
        label="Add a new note..."
        multiline
        rows={3}
        value={currentNote}
        onChange={(e) => setCurrentNote(e.target.value)}
        placeholder="Write your note here..."
        variant="outlined"
        sx={{ borderRadius: 1 }}
      />
    </Grid>
    <Grid item sx={{ display: 'flex', alignItems: 'flex-end' }}>
      <Button variant="contained" color="primary" onClick={handleAddNote}>
        Add Note
      </Button>
    </Grid>
  </Grid>
  <Accordion sx={{ mt: 3, bgcolor: 'action.hover' }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography sx={{ color: 'text.primary' }}>View Notes History</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ maxHeight: '200px', overflowY: 'auto' }}>
      <List>
        {selectedQuote?.notes?.map((note, index) => (
          <ListItem
            key={index}
            sx={{
              my: 0.5,
              p: 2,
              borderRadius: '4px',
              bgcolor: 'lightblue',
            }}>
            <ListItemText
              primary={note.content}
              secondary={new Date(note.timeStamp).toLocaleString()}
              primaryTypographyProps={{ fontSize: '1rem', fontWeight: 'medium' }}
              secondaryTypographyProps={{ fontSize: '0.75rem' }}
            />
          </ListItem>
        ))}
      </List>
    </AccordionDetails>
  </Accordion>

        </div>
    );
};

export default NotesSection;
