import EnhancedFile from '../Models/EnhancedFile';
import Project from '../Models/Project';


class ProjectService {

  baseUrl: string;
  httpClient: any;

  constructor(httpClient: any) {
    this.baseUrl = process.env.REACT_APP_API_URL!;
    this.httpClient = httpClient;
  }

  async getProjectsByOrganizationId(orgId: number) {
    try {
      const response = await this.httpClient.get(`${this.baseUrl}/Projects/getProjectsByOrganization/${orgId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching projects for organization ${orgId}:`, error);
      throw error;
    }
  }

  //get project by contractorId
  async getProjectsByContractorId(contractorId: number) {
    try {
      const response = await this.httpClient.get(`${this.baseUrl}/Projects/getProjectsByContractorId/${contractorId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching projects for contractor ${contractorId}:`, error);
      throw error;
    }
  }
  

  async UploadFiles(projectId: number, formData: FormData) {
    try {
      const response = await this.httpClient.post(`${this.baseUrl}/Projects/upload/${projectId}`, formData);
      return response.data;
    } catch (error) {
      console.error(`Error uploading files:`, error);
      throw error;
    }
  }

  async HandleLoadFiles(projectId: number) {
    try {
      const response = await this.httpClient.get(`${this.baseUrl}/Projects/${projectId}/files`);
      const transformedData = response.data.map((file: EnhancedFile) => ({
        ...file,
        // Additional transformations if needed
        // For example, converting date strings to Date objects
        uploadDate: new Date(file.uploadDate)
      }));
      console.log('Transformed files:', transformedData);
      return transformedData;
    } catch (error) {
      console.error(`Error uploading files:`, error);
      throw error;
    }
  }

  async getOrganizations() {
    try {
      const response = await this.httpClient.get(`${this.baseUrl}/Organization/getAllOrganizations`);
      return response.data;
    } catch (error) {
      console.error("Error fetching organizations:", error);
      throw error;
    }
  }

  async getAllProjects() {
    try {
      const response = await this.httpClient.get(`${this.baseUrl}/Projects`);
      return response.data;
    } catch (error) {
      console.error("Error fetching all projects:", error);
      throw error;
    }
  }

  async getProjectById(id: number) {
    try {
      const response = await this.httpClient.get(`${this.baseUrl}/Projects/getProjectById/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching project with id ${id}:`, error);
      throw error;
    }
  }

  async createProject(project: Project) {
    try {
      const response = await this.httpClient.post(`${this.baseUrl}/Projects/createProject`, project);
      return response.data;
    } catch (error) {
      console.error("Error creating project:", error);
      throw error;
    }
  }

  async updateProject(id: number, project: Project) {
    try {
      const response = await this.httpClient.put(`${this.baseUrl}/Projects/updateProject/${id}`, project);
      return response.data;
    } catch (error) {
      console.error(`Error updating project with id ${id}:`, error);
      throw error;
    }
  }

  async deleteProject(id: number) {
    try {
      const response = await this.httpClient.delete(`${this.baseUrl}/deleteProject/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting project with id ${id}:`, error);
      throw error;
    }
  }

  async getAllEmployees() {
    try {
      const response = await this.httpClient.get(`${this.baseUrl}/Projects/getEmployees`);
      return response.data;
    } catch (error) {
      console.error("Error fetching all employees:", error);
      throw error;
    }
  }

  async getEmployeeById(id: number) {
    try {
      const response = await this.httpClient.get(`${this.baseUrl}/Projects/getEmployeeById/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching employee with id ${id}:`, error);
      throw error;
    }
  }
}


export default ProjectService;
