import httpClient from '../Utilities/AxiosInstance';
import InspectionFormObject from '../Models/InspectionForm';

class InspectionFormsService {
  baseUrl: string;
  httpClient: any;

  constructor(httpClient: any) {
    this.baseUrl = process.env.REACT_APP_API_URL!;
    this.httpClient = httpClient;
  }

  async saveInspectionForm(inspectionForm: InspectionFormObject) {
    try {
      const response = await this.httpClient.post(`${this.baseUrl}/InspectionForms/createinspectionform`, inspectionForm);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  }

  async updateInspectionForm(inspectionForm: InspectionFormObject) {
    try {
      const response = await this.httpClient.put(`${this.baseUrl}/InspectionForms/updateinspectionform`, inspectionForm);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  }

  async updateInspectionFormStatus(formId: number, reviewerComments: string, status: string, signature: string) {
    try {
      if (!reviewerComments) reviewerComments = "No comments provided";
      const body = { signature };
      const response = await this.httpClient.put(`${this.baseUrl}/InspectionForms/updateinspectionformapprovalstatus/${formId}/${encodeURIComponent(reviewerComments)}/${encodeURIComponent(status)}`, body);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  }

  async getInspectionFormByVehicleId(vehicleId: number) {
    try {
      const response = await this.httpClient.get(`${this.baseUrl}/InspectionForms/getinspectionformbyvehicleid/${vehicleId}`);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  }

  async uploadBase64FileToS3(base64File: string, fileCategory: string): Promise<string> {
    // Extract file type and data from base64 string
    const match = base64File.match(/^data:(.+);base64,(.+)$/);
    if (!match) {
      throw new Error('Invalid base64 file string');
    }

    const fileType = match[1];
    const fileData = match[2];
    const fileName = `${fileCategory}-${Date.now()}`;

    // Send the base64 file and metadata to the backend
    try {
      const response = await this.httpClient.post(`${this.baseUrl}/InspectionForms/upload-file`, {
        fileName,
        fileType,
        fileData,
        fileCategory
      });

      return response.data.url;  // The backend returns the final S3 URL
    } catch (error: any) {
      throw new Error('Failed to upload file to the backend');
    }
  }

  // Updated method to handle uploading multiple discrepancy files and linking to an inspection form
  async uploadDiscrepancyFiles(base64Files: string[], fileCategory: string, formId: number, contractorId: string, vehicleId: string): Promise<{ formId: number, urls: string[] }> {
    try {
      const response = await this.httpClient.post(`${this.baseUrl}/InspectionForms/upload-discrepancy-files/${formId}/${contractorId}/${vehicleId}`, {
        files: base64Files,
        fileCategory,
      });
      return response.data;  // Return the object containing formId and urls
    } catch (error: any) {
      console.error('Error uploading discrepancy files:', error);
      throw error;
    }
  }


  async uploadEquipmentFile(
    base64File: string,
    fileCategory: string,
    formId: number | null, // Allow formId to be nullable for new forms
    equipmentId: number,
    instanceIndex: number,
    contractorId: string,
    vehicleId: string,
  ): Promise<{ url: string; formId: number }> {
    try {
      const response = await this.httpClient.post(
        `${this.baseUrl}/InspectionForms/upload-equipment-file/${formId ?? 0}/${equipmentId}/${instanceIndex}/${contractorId}/${vehicleId}`,
        {
          files: [base64File],
          fileCategory,
        }
      );

      return {
        url: response.data.url,       // URL of the uploaded file
        formId: response.data.formId  // The formId, either existing or newly created
      };
    } catch (error: any) {
      console.error('Error uploading equipment file:', error);
      throw error;
    }
  }

}

export default InspectionFormsService;
