import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

function Appbar() {
  const { isAuthenticated, user, logout } = useAuth0();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // Assuming the role extraction logic is defined elsewhere or consider implementing it
  const namespace = `${baseUrl}/roles`; // Using the correct namespace
  const userRoles = user ? user[namespace] || [] : [];
  const isAdmin = userRoles.includes('Admin');
  const isContractor = userRoles.includes('contractor');

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin } as LogoutOptions);
  };

  return (
    <AppBar position="static" sx={{ width: '100%' }}>
      <Toolbar disableGutters>
        <Box>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: 'block',
            }}
          >
            {!isAuthenticated && (
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Button component={RouterLink} to="/" sx={{ color: 'black' }}>Sign In</Button>
                </Typography>
              </MenuItem>
            )}
            {isAuthenticated && isContractor && (
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Button component={RouterLink} to="/contractor" sx={{ color: 'black' }}>Contractor</Button>
                </Typography>
              </MenuItem>
            )}
            {/* Conditional rendering based on roles */}
            {isAuthenticated && isAdmin && (
              <div>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <Button component={RouterLink} to="/ProjectDashboard" sx={{ color: 'black' }}>Project Dashboard</Button>
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <Button component={RouterLink} to="/QuoteDashboard" sx={{ color: 'black' }}>Quote Dashboard</Button>
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <Button component={RouterLink} to="/AdminApprovalDashboard" sx={{ color: 'black' }}>Admin Approval Dashboard</Button>
                  </Typography>
                </MenuItem>
              </div>
            )}
            {isAuthenticated && (
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Sign Out</Typography>
              </MenuItem>
            )}
          </Menu>
        </Box>
        {isAuthenticated && (
          <RouterLink to="/profile" style={{ textDecoration: 'none', marginLeft: 'auto' }}>
            <IconButton
              aria-label="user profile"
              color="inherit"
            >
              <Avatar alt="Profile Picture" src={user?.picture} />
            </IconButton>
          </RouterLink>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Appbar;
