const preCheckQuestions = [
    { id: 'windowsCondition', question: 'Are windows, windshield, or mirrors free of cracks, or breaks?' },
    { id: 'wipersOperational', question: 'Are windshield wipers operational?' },
    { id: 'engineOperational', question: 'Is the vehicle engine operational?' },
    { id: 'frontDoorOperational', question: 'Is the front door operational?' },
    { id: 'rearDoorOperational', question: 'Is the rear door operational?' },
    { id: 'wheelchairLiftOperational', question: 'Is a wheelchair lift operational?' },
    { id: 'radioPAOperational', question: 'Is the radio/PA operational?' },
    { id: 'interiorLightsOperational', question: 'Is the interior lights operational? (aisle lamps, door lamps)' },
    { id: 'vehiclePanelsCondition', question: 'Are the vehicle panels have all screws/rivets and are not cracked/broken?' },
    { id: 'HVACOperational', question: 'Is the heat AC, & defrost systems operational?' },
    { id: 'hornOperational', question: 'Is the horn operational?' },
    { id: 'exteriorLightsOperational', question: 'Are the exterior lights operational? (running, backup, 8-ways)' },
    { id: 'interiorLocksOperational', question: 'Are the interior locks operational?' },
    { id: 'speakersOperational', question: 'Are the speakers operational?' },
    { id: 'cleanBeforeInstallation', question: 'Is the vehicle clean from debris before the installation?' },
  ];

  export default preCheckQuestions;