import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <main style={{ padding: '1rem', textAlign: 'center' }}>
      <h1>Access Denied</h1>
      <p>You do not have permission to view this page.</p>
      <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
        <Button variant="contained" onClick={() => navigate(-1)}>
          Go Back
        </Button>
        &nbsp; {/* Adding some space between buttons */}
        <Button variant="contained" color="primary" onClick={() => navigate('/')}>
          Home
        </Button>
      </Grid>
    </main>
  );
};

export default Unauthorized;
