import React, { useState } from 'react';
import { Button, Typography, Box, Table, TableHead, TableRow, TableCell, TableBody, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import Vehicle from '../../Models/Vehicle';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px',
  },
  title: {
    marginBottom: '16px',
  },
  uploadButton: {
    marginRight: '16px',
  },
  tableContainer: {
    border: '1px solid black',
    marginTop: '16px',
    padding: '16px',
  },
  tableHeaderCell: {
    fontWeight: 'bold',
  },
}));

const VehcileFileUploadComponent = () => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadedVehicles, setUploadedVehicles] = useState<Vehicle[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('file', selectedFile);

        const response = await axios.post('https://localhost:44319/api/vehicles/single-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const uploadedData = response.data;
        console.log(uploadedData);
        setUploadedVehicles(uploadedData); // Update the list of uploaded vehicles

        console.log(uploadedData); // Handle the response data as needed
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }  
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        File Upload
      </Typography>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <Button variant="contained" onClick={handleUpload} disabled={!selectedFile} className={classes.uploadButton}>
        Upload
      </Button>

      <Box className={classes.tableContainer}>
        <Typography variant="h6">Uploaded Vehicles ({uploadedVehicles.length})</Typography>
        {uploadedVehicles.length === 0 ? (
          <Typography variant="body1">No vehicles uploaded yet.</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>Vehicle ID</TableCell>
                <TableCell className={classes.tableHeaderCell}>Organization ID</TableCell>
                <TableCell className={classes.tableHeaderCell}>Make</TableCell>
                <TableCell className={classes.tableHeaderCell}>Model</TableCell>
                <TableCell className={classes.tableHeaderCell}>Project Number</TableCell>
                <TableCell className={classes.tableHeaderCell}>VIN</TableCell>
                <TableCell className={classes.tableHeaderCell}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadedVehicles.map((vehicle) => (
                <TableRow key={vehicle.vehicleId}>
                  <TableCell>{vehicle.vehicleId}</TableCell>
                  <TableCell>{vehicle.organizationId}</TableCell>
                  <TableCell>{vehicle.make}</TableCell>
                  <TableCell>{vehicle.model}</TableCell>
                  <TableCell>{vehicle.vin}</TableCell>
                  <TableCell>{vehicle.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </Box>
  );
};

export default VehcileFileUploadComponent;
