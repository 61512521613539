import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';
import EquipmentSection from '../ContractorInspectionComponents/EquipmentSection';
import ReviewSection from '../ContractorInspectionComponents/ReviewSection';
import { SignatureSection } from '../ContractorInspectionComponents/SignatureSection';
import VehicleFormSection from '../ContractorInspectionComponents/VehicleFormSection';
import InspectionFormObject from '../../Models/InspectionForm';
import EquipmentFileDetail from '../../Models/EquipmentFileDetail';
import Contractor from '../../Models/Contractor';
import VehicleEquipment from '../../Models/VehicleEquipment';
import Vehicle from '../../Models/Vehicle';
import WarningIcon from '@mui/icons-material/Warning';
import InstallationCheckQuestions from '../ContractorInspectionComponents/QuestionSections/InstallationCheckQuestions';
import PreCheckQuestions from '../ContractorInspectionComponents/QuestionSections/PreCheckQuestions';
import PostInstallQuestions from '../ContractorInspectionComponents/QuestionSections/PostInstallQuestions';
import preCheckQuestions from '../../Utilities/PreCheckQuestions';
import installationCheckQuestions from '../../Utilities/InstallationCheckQuestions';
import postInstallQuestions from '../../Utilities/PostInstallQuestions';
import DiscrepancySection from '../ContractorInspectionComponents/DiscrepancySection';
import ContractorService from '../../Services/ContractorService';
import EquipmentService from '../../Services/EquipmentService';
import InspectionFormsService from '../../Services/InspectionFormsService';
import { saveFormDataToIndexedDB, loadFormDataFromIndexedDB, clearIndexedDB } from '../../Utilities/indexedDb';
import { initLogGroupAndStream, logError, logInfo } from '../../Utilities/AWSConfig';


interface InspectionFormProps {
  mode: 'submit' | 'review' | 'reviewPM' | 'adminApproval';
  open: boolean;
  onClose: () => void;
  initialVehicle: Vehicle;
  onSave: (data: InspectionFormObject, vehicle: Vehicle) => Promise<void>;
  contractorDetails: Contractor; // Defined more explicit types
  onReview: (data: InspectionFormObject) => Promise<void>;
}


const InspectionForm: React.FC<InspectionFormProps> = ({
  mode,
  open,
  onClose,
  initialVehicle,
  onSave,
  contractorDetails,
  onReview,
}) => {
  const initialFormState: InspectionFormObject = {
    formId: null,
    contractorId: '',
    vehicleId: '',
    vehicleIdImage: null,
    vehicleIdImagePath: '',
    vinImage: null,
    vinImagePath: '',
    discrepancyDescription: '',
    discrepancyImages: [],
    discrepancyImagePaths: [],
    inspectionDate: new Date(),
    installationCheckAnswersSerialized: '',
    preCheckAnswersSerialized: '',
    postInstallAnswersSerialized: '',
    signature: '',
    status: '',
    statusSiteLead: '',
    siteLeadComments: '',
    siteLeadSignature: '',
    projectManagerApprovalStatus: '',
    projectManagerComments: '',
    projectManagerSignature: '',
    adminApprovalStatus: '',
    adminApprovalComments: '',
    adminSignature: '',
    equipmentDetails: [],
    vehicleIdImageBase64: '',
    vinImageBase64: '',
    discrepancyImagesBase64: [],
    assistingContractorId: 0,
    createdDate: new Date(),
    lastUpdatedDate: new Date(),
  };

  const [formState, setFormState] = useState<InspectionFormObject>(initialFormState);

  const equipmentDetailsMemo = useMemo(() => {
    return formState.equipmentDetails || [];
  }, [formState.equipmentDetails]);

  const discrepancyImagesMemo = useMemo(() => {
    return formState.discrepancyImagePaths || [];
  }, [formState.discrepancyImagePaths]);

  const contractorFullName = useMemo(() => {
    return `${contractorDetails.firstName || ''} ${contractorDetails.lastName || ''}`;
  }, [contractorDetails]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [assistingContractorList, setAssistingContractorList] = useState<Contractor[]>([]);
  const [vehicleEquipment, setVehicleEquipment] = useState<VehicleEquipment[]>([]);
  const [updatedVehicle, setUpdatedVehicle] = useState<Vehicle | null>(initialVehicle);
  const [projectManagerStatus, setProjectManagerStatus] = useState<string>('');
  const [adminStatus, setAdminStatus] = useState<string>('');
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const httpClient = useMemo(() => axios.create(), []);
  const contractorService = useMemo(() => new ContractorService(httpClient), [httpClient]);
  const equipmentService = useMemo(() => new EquipmentService(httpClient), [httpClient]);
  const inspectionFormsService = useMemo(() => new InspectionFormsService(httpClient), [httpClient]);

  const readFileAsBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      reader.onload = () => {
        // Strip the prefix (if necessary)
        const base64String = (reader.result as string).split(',')[1]; // Remove the `data:image/jpeg;base64,` part
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const LOCAL_STORAGE_KEY = 'inspectionFormData';

  useEffect(() => {
    // Reset form state to initial state before loading data
    const resetFormState = () => {
      setFormState(initialFormState); // Reset to initial form state before loading new data
    };

    const loadFormData = async () => {
      try {
        // Load any form data from IndexedDB (if available)
        const loadedFormData = await loadFormDataFromIndexedDB(LOCAL_STORAGE_KEY);
        setFormState(loadedFormData || initialFormState);
      } catch (error) {
        await logError({ message: 'Error loading form data from IndexedDB', error });
      }
    };

    resetFormState(); // Reset state on component load
    loadFormData(); // Then load any data from IndexedDB
  }, []);

  useEffect(() => {
    const initData = async () => {
      if (!initialVehicle) return;

      // Reset form state when a new vehicle is loaded
      setFormState(initialFormState);

      try {
        // Fetch contractor data, equipment data, and form data in parallel
        const [contractorData, equipmentData, formData] = await Promise.all([
          contractorService.getAllContractors(),
          equipmentService.GetVehicleEquipmentByVehicleId(initialVehicle.vehicleId),
          inspectionFormsService.getInspectionFormByVehicleId(initialVehicle.vehicleId),
        ]);

        // Set the assisting contractors list and vehicle equipment data
        setAssistingContractorList(contractorData);
        setVehicleEquipment(equipmentData);

        // Update the form state with data from the form and the initial vehicle
        setFormState((prevState) => ({
          ...initialFormState,  // Start from the initial form state each time
          formId: formData.formId || 0,
          contractorId: contractorDetails.contractorId.toString(),
          vehicleId: initialVehicle.vehicleId,
          assistingContractorId: formData.assistingContractorId ?? 0,  // Ensure this is retained
          equipmentDetails: equipmentData,
          discrepancyImagesBase64: formData.discrepancyImagesBase64 || [],
          discrepancyImagePaths: formData.discrepancyImagePaths || [],
          discrepancyDescription: formData.discrepancyDescription || '',
          inspectionDate: formData.inspectionDate ? new Date(formData.inspectionDate) : new Date(),
          signature: formData.signature || '',
          status: formData.status || '',
          statusSiteLead: formData.statusSiteLead || '',
          siteLeadComments: formData.siteLeadComments || '',
          siteLeadSignature: formData.siteLeadSignature || '',
          projectManagerApprovalStatus: formData.projectManagerApprovalStatus || '',
          projectManagerComments: formData.projectManagerComments || '',
          projectManagerSignature: formData.projectManagerSignature || '',
          adminApprovalStatus: formData.adminApprovalStatus || '',
          adminApprovalComments: formData.adminApprovalComments || '',
          adminSignature: formData.adminSignature || '',
          vehicleIdImageBase64: formData.vehicleIdImageBase64 || '',
          vinImageBase64: formData.vinImageBase64 || '',
          createdDate: formData.createdDate ? new Date(formData.createdDate) : new Date(),
          lastUpdatedDate: formData.lastUpdatedDate ? new Date(formData.lastUpdatedDate) : new Date(),
          installationCheckAnswersSerialized: formData.installationCheckAnswersSerialized || '',
          preCheckAnswersSerialized: formData.preCheckAnswersSerialized || '',
          postInstallAnswersSerialized: formData.postInstallAnswersSerialized || '',
          ...formData,  // Spread formData to ensure other necessary fields are populated
        }));

        // Set the vehicle state to the initial vehicle
        setUpdatedVehicle(initialVehicle);

        // Set project manager and admin statuses based on form data
        setProjectManagerStatus(
          formData.projectManagerApprovalStatus === 'Project Manager Approved'
            ? 'Approved'
            : formData.projectManagerApprovalStatus === 'Project Manager Declined'
              ? 'Declined'
              : ''
        );

        setAdminStatus(
          formData.adminApprovalStatus === 'Admin Approved'
            ? 'Approved'
            : formData.adminApprovalStatus === 'Admin Declined'
              ? 'Declined'
              : ''
        );
        setUpdatedVehicle(initialVehicle);
      } catch (error) {
        await logError({ message: 'Initialization error', error });
      }
    };

    if (initialVehicle) {
      initData();  // Load data when a new vehicle is provided
    }
  }, [initialVehicle, contractorService, equipmentService, inspectionFormsService, contractorDetails.contractorId]);


  useEffect(() => {
    const saveData = async () => {
      try {
        await saveFormDataToIndexedDB(LOCAL_STORAGE_KEY, formState);
      } catch (error) {
        await logError({ message: 'Error saving form data to IndexedDB', error });
      }
    };

    saveData();
  }, [formState]);

  const saveFormDataToIndexedDBWrapper = async (key: string, data: InspectionFormObject) => {
    try {
      await saveFormDataToIndexedDB(key, data);
      // setSnackbarMessage('Form data saved to local storage.');
      // setSnackbarSeverity('info');
      // setSnackbarOpen(true);
    } catch (error) {
      await logError({ message: 'Error saving to IndexedDB', error });
      setSnackbarMessage('Failed to save data to local storage.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const readFileAndUpload = async (
    file: File,
    field: keyof EquipmentFileDetail,
    formId: number | null,
    equipmentId: number,
    instanceIndex: number,
    contractorId: string,
    vehicleId: string
  ): Promise<{ base64File: string; uploadedPath: string; returnedFormId: number }> => {
    try {
      // Convert file to base64
      const base64File = await readFileAsBase64(file);
  
      // Upload the file using your inspection form service
      const { url: uploadedPath, formId: returnedFormId } = await inspectionFormsService.uploadEquipmentFile(
        base64File,
        field,
        formId!,
        equipmentId,
        instanceIndex,
        contractorId,
        vehicleId
      );
  
      return { base64File, uploadedPath, returnedFormId };
    } catch (error) {
      throw new Error(`Failed to read and upload file for ${field}: ${(error as Error).message}`);
    }
  };  

  const handleEquipmentChange = async (
    equipmentId: number,
    instanceIndex: number,
    field: keyof EquipmentFileDetail,
    value: any,
    retries = 3
  ): Promise<void> => {
    try {
      setIsUploading(true);
  
      if (
        field === 'equipmentImage' ||
        field === 'serialNumberImage' ||
        field === 'installationImage' ||
        field === 'calibrationImage'
      ) {
        const file = value as File;
        const tempUrl = URL.createObjectURL(file); // Temporary URL for UI update
  
        // Immediately update form state with the temporary URL for the image
        setFormState((prevFormState) => {
          const updatedEquipmentDetails = prevFormState.equipmentDetails.map((detail) => {
            if (detail.equipmentId === equipmentId && detail.instanceIndex === instanceIndex) {
              return {
                ...detail,
                [`${field}Path`]: tempUrl, // Update with temporary URL
              };
            }
            return detail;
          });
  
          return {
            ...prevFormState,
            equipmentDetails: updatedEquipmentDetails,
          };
        });
  
        // Upload the file and receive the actual path
        const fileBase64 = await readFileAsBase64(file);
        const { url: uploadedPath, formId: returnedFormId } = await inspectionFormsService.uploadEquipmentFile(
          fileBase64,
          field,
          formState.formId!,
          equipmentId,
          instanceIndex,
          formState.contractorId,
          formState.vehicleId
        );
  
        // After upload, update form state with the final URL
        setFormState((prevFormState) => {
          const updatedEquipmentDetails = prevFormState.equipmentDetails.map((detail) => {
            if (detail.equipmentId === equipmentId && detail.instanceIndex === instanceIndex) {
              return {
                ...detail,
                [`${field}Base64`]: fileBase64,
                [`${field}Path`]: uploadedPath, // Update with final uploaded path
              };
            }
            return detail;
          });
  
          return {
            ...prevFormState,
            formId: returnedFormId || prevFormState.formId, // Ensure formId is updated
            equipmentDetails: updatedEquipmentDetails,
          };
        });
      } else {
        // Handle non-image fields
        setFormState((prevFormState) => {
          const updatedEquipmentDetails = prevFormState.equipmentDetails.map((detail) => {
            if (detail.equipmentId === equipmentId && detail.instanceIndex === instanceIndex) {
              return {
                ...detail,
                [field]: value,
              };
            }
            return detail;
          });
  
          return {
            ...prevFormState,
            equipmentDetails: updatedEquipmentDetails,
          };
        });
      }
  
      saveFormDataToIndexedDBWrapper(LOCAL_STORAGE_KEY, formState);
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
  
      if (retries > 0) {
        console.warn(`Retrying file upload for ${field} (retries left: ${retries - 1})`);
        return handleEquipmentChange(equipmentId, instanceIndex, field, value, retries - 1);
      }
  
      setUploadError(`Failed to upload ${field} after multiple attempts.`);
      console.error(`Error in handleEquipmentChange: ${(error as Error).message}`);
    }
  };
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const validateForm = () => {
    if (mode === 'review' && (!formState.siteLeadSignature || !formState.statusSiteLead)) {
      return false;
    }
    if (mode === 'reviewPM' && (!formState.projectManagerSignature || !formState.projectManagerApprovalStatus)) {
      return false;
    }
    if (mode === 'adminApproval' && (!formState.adminSignature || !formState.adminApprovalStatus)) {
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    if ((!updatedVehicle?.vehicleNumber || !updatedVehicle?.vin) && formState.signature !== '') {
      setSnackbarMessage('Vehicle Number and VIN are required.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);

      // Scroll to the vehicle section
      document.getElementById('vehicle-section')?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    try {
      await onSave(formState, updatedVehicle as Vehicle);
      setFormState(initialFormState);
      clearIndexedDB(LOCAL_STORAGE_KEY);
      onClose();
      //  await logInfo({ message: 'Inspection form saved successfully', formState });
    } catch (error) {
      setSnackbarMessage('Error saving form');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      await logError({ message: 'Error saving form', error });
    }
  };

  const handleReviewSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      await onReview(formState);
      onClose();
      //await logInfo({ message: 'Review submitted successfully', formState });
    } catch (error) {
      setSnackbarMessage('Error submitting review');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      await logError({ message: 'Error submitting review', error });
    }
  };

  const handleVehicleChange = useCallback((field: keyof Vehicle, value: any) => {
    setUpdatedVehicle((prevVehicle) => ({
      ...prevVehicle!,
      [field]: value,
    }) as Vehicle);
  }, []);

  const [, forceUpdate] = useState(0);

  const handleStatusChange = useCallback((reviewType: string, status: string) => {

    setFormState((prevState) => {
      let newState;
      if (reviewType === 'Site Lead') {
        newState = { ...prevState, statusSiteLead: status };
      } else if (reviewType === 'Project Manager') {
        newState = { ...prevState, projectManagerApprovalStatus: status === 'Approved' ? 'Project Manager Approved' : 'Project Manager Declined' };
      } else if (reviewType === 'Admin') {
        newState = { ...prevState, adminApprovalStatus: status === 'Approved' ? 'Admin Approved' : 'Admin Declined' };
      } else {
        newState = prevState;
      }

      forceUpdate((prev) => prev + 1); // Trigger re-render
      return newState;
    });
  }, []);
  const handleCommentChange = useCallback((reviewType: string, comments: string) => {
    setFormState((prevState) => {
      switch (reviewType) {
        case 'Site Lead':
          return { ...prevState, siteLeadComments: comments };
        case 'Project Manager':
          return { ...prevState, projectManagerComments: comments };
        case 'Admin':
          return { ...prevState, adminApprovalComments: comments };
        default:
          return prevState;
      }
    });
  }, []);

  const handleSignatureComplete = (type: string, signature: string) => {
    setFormState((prevState) => {
      switch (type) {
        case 'general':
          return { ...prevState, signature };
        case 'Site Lead':
          return { ...prevState, siteLeadSignature: signature };
        case 'Project Manager':
          return { ...prevState, projectManagerSignature: signature };
        case 'Admin':
          return { ...prevState, adminSignature: signature };
        default:
          return prevState;
      }
    });
  };

  const handleClearSignature = (type: string) => {
    setFormState((prevState) => {
      switch (type) {
        case 'general':
          return { ...prevState, signature: '' };
        case 'Site Lead':
          return { ...prevState, siteLeadSignature: '' };
        case 'Project Manager':
          return { ...prevState, projectManagerSignature: '' };
        case 'Admin':
          return { ...prevState, adminSignature: '' };
        default:
          return prevState;
      }
    });
  };

  const renderReviewComments = () => {
    const comments = [];

    if (mode !== 'review' && mode !== 'reviewPM' && mode !== "adminApproval" && formState.siteLeadComments) {
      comments.push({ type: 'Site Lead', text: formState.siteLeadComments });
    }

    if (mode === 'review' && formState.projectManagerComments) {
      comments.push({ type: 'Project Manager', text: formState.projectManagerComments });
    }

    if (mode === 'reviewPM' && formState.adminApprovalComments) {
      comments.push({ type: 'Admin', text: formState.adminApprovalComments });
    }

    if (comments.length === 0) return null;

    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        <Card variant="outlined" sx={{ borderColor: 'red', backgroundColor: '#fff5f5', borderRadius: 2 }}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', color: 'red', mb: 2 }}>
              <WarningIcon sx={{ mr: 1 }} />
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Attention Needed
              </Typography>
            </Box>
            {comments.map((comment, index) => (
              <Box key={index} sx={{ mb: 2, pl: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'red', mb: 1 }}>
                  {comment.type} Comments:
                </Typography>
                <Typography variant="body2" sx={{ color: 'black', pl: 2 }}>
                  {comment.text}
                </Typography>
              </Box>
            ))}
          </CardContent>
        </Card>
      </Box>
    );
  };

  const handlePreCheckChange = (questionId: string, answer: string) => {
    setFormState((prev) => ({
      ...prev,
      preCheckAnswersSerialized: JSON.stringify({
        ...JSON.parse(prev.preCheckAnswersSerialized || '{}'),
        [questionId]: answer,
      }),
    }));
  };

  const handleInstallationCheckChange = (questionId: string, answer: string) => {
    setFormState((prev) => ({
      ...prev,
      installationCheckAnswersSerialized: JSON.stringify({
        ...JSON.parse(prev.installationCheckAnswersSerialized || '{}'),
        [questionId]: answer,
      }),
    }));
  };

  const handlePostInstallChange = (questionId: string, answer: string) => {
    setFormState((prev) => ({
      ...prev,
      postInstallAnswersSerialized: JSON.stringify({
        ...JSON.parse(prev.postInstallAnswersSerialized || '{}'),
        [questionId]: answer,
      }),
    }));
  };

  const handleDiscrepancyDescriptionChange = (description: string) => {
    setFormState((prevState) => ({
      ...prevState,
      discrepancyDescription: description,
    }));
  };

  const handleDiscrepancyImageAdd = async (formData: FormData): Promise<string[]> => {
    setIsUploading(true); // Start loading spinner

    const files: File[] = [];
    formData.forEach((value) => {
      if (value instanceof File) {
        files.push(value);
      }
    });

    try {
      // Convert files to base64 strings
      const base64Files = await Promise.all(files.map((file) => readFileAsBase64(file)));

      // Pass 0 for formId if it doesn't exist yet
      const formIdToUse = formState.formId || 0;

      // Upload files and receive the updated formId and uploaded paths
      console.log('formState', formState);
      const response = await inspectionFormsService.uploadDiscrepancyFiles(
        base64Files,
        'DiscrepancyImage',
        formIdToUse,
        formState.contractorId, // Pass contractorId
        formState.vehicleId // Pass vehicleId
      );

      const { formId: returnedFormId, urls: uploadedPaths } = response || {};

      if (!uploadedPaths || !uploadedPaths.length) {
        throw new Error('No paths returned from upload');
      }

      // Update the form state with the new formId if provided
      setFormState((prevState) => {
        const newFormId = returnedFormId || prevState.formId; // Use returned formId if available

        return {
          ...prevState,
          formId: newFormId,
          discrepancyImagesBase64: [...prevState.discrepancyImagesBase64, ...base64Files],
          discrepancyImagePaths: [...prevState.discrepancyImagePaths, ...uploadedPaths.filter(Boolean)], // Filter out any falsy values
        };
      });

      setIsUploading(false); // Stop loading spinner
      return uploadedPaths;
    } catch (error) {
      setIsUploading(false); // Stop loading spinner in case of error
      setSnackbarMessage('Error uploading discrepancy image');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      await logError({ message: 'Error uploading discrepancy image', error });
      return []; // Return an empty array in case of error
    }
  };


  const handleDiscrepancyImageRemove = (index: number) => {
    setFormState((prevState) => {
      const updatedImages = [...prevState.discrepancyImages];
      updatedImages.splice(index, 1);
      const updatedImagesBase64 = [...prevState.discrepancyImagesBase64];
      updatedImagesBase64.splice(index, 1);
      const updatedPaths = [...prevState.discrepancyImagePaths];
      updatedPaths.splice(index, 1);
      return {
        ...prevState,
        discrepancyImages: updatedImages,
        discrepancyImagesBase64: updatedImagesBase64,
        discrepancyImagePaths: updatedPaths,
      };
    });
  };

  const handlePathImageRemove = (index: number) => {
    setFormState((prevState) => {
      const updatedPaths = [...prevState.discrepancyImagePaths];
      updatedPaths.splice(index, 1);
      return {
        ...prevState,
        discrepancyImagePaths: updatedPaths,
      };
    });
  };

  function handleFieldChange(field: keyof InspectionFormObject, value: any): void {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  }

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Vehicle Inspection Form</DialogTitle>
      <DialogContent>
        {isUploading && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 9999,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
              <Typography variant="h6" component="div" color="white" sx={{ marginTop: 2 }}>
                Uploading...
              </Typography>
            </Box>
          </Box>
        )}
        {renderReviewComments()}
        <VehicleFormSection
          id="vehicle-section"
          formState={formState}
          onFieldChange={handleFieldChange}
          vehicle={updatedVehicle as Vehicle}
          onVehicleChange={handleVehicleChange}
          contractors={assistingContractorList}
        />
        <PreCheckQuestions
          questions={preCheckQuestions}
          answers={JSON.parse(formState.preCheckAnswersSerialized || '{}')}
          onAnswerChange={handlePreCheckChange}
        />
        <Box sx={{ mb: 2 }}>
          <DiscrepancySection
            discrepancyDescription={formState.discrepancyDescription}
            discrepancyImagePaths={formState.discrepancyImagePaths}
            onDescriptionChange={handleDiscrepancyDescriptionChange}
            onImageAdd={handleDiscrepancyImageAdd}
            onPathImageRemove={handlePathImageRemove}
          />
        </Box>
        <EquipmentSection
          vehicleEquipment={vehicleEquipment}
          equipmentDetails={formState.equipmentDetails}
          onEquipmentChange={handleEquipmentChange}
        />
        <InstallationCheckQuestions
          questions={installationCheckQuestions}
          answers={JSON.parse(formState.installationCheckAnswersSerialized || '{}')}
          onAnswerChange={handleInstallationCheckChange}
        />
        <PostInstallQuestions
          questions={postInstallQuestions}
          answers={JSON.parse(formState.postInstallAnswersSerialized || '{}')}
          onAnswerChange={handlePostInstallChange}
        />
        <Box sx={{ mt: 4, mb: 2 }}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="body1" sx={{ mb: 2 }}>
                By signing below, I, <strong>{contractorDetails.firstName || ''} {contractorDetails.lastName || ''}</strong>, certify that I have thoroughly tested the system and determined that it meets all specified requirements and functions correctly.
              </Typography>

              <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                {formState.inspectionDate ?
                  `Inspection Date: ${new Date(formState.inspectionDate).toLocaleDateString()}` :
                  'Not yet signed'}
              </Typography>

              <SignatureSection
                type="general"
                signature={formState.signature ?? ''}
                onSignatureComplete={handleSignatureComplete}
                onClearSignature={handleClearSignature}
                readonly={mode === 'review' || mode === 'adminApproval' || mode === 'reviewPM'}
              />
            </CardContent>
          </Card>
        </Box>
        {(mode === 'review' || mode === 'reviewPM' || mode === 'adminApproval') && (
          <Card variant="outlined" sx={{ mt: 2, mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {`Site Lead Review:`}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <SignatureSection
                  type="Site Lead"
                  signature={formState.siteLeadSignature}
                  onSignatureComplete={handleSignatureComplete}
                  onClearSignature={handleClearSignature}
                  readonly={mode !== 'review'}
                />
              </Box>
              <Box>
                <ReviewSection
                  reviewStatus={formState.statusSiteLead}
                  comments={formState.siteLeadComments}
                  reviewType="Site Lead"
                  onChangeStatus={handleStatusChange}
                  onChangeComments={handleCommentChange}
                />
              </Box>
            </CardContent>
          </Card>
        )}
        {(mode === 'reviewPM' || mode === 'adminApproval') && (
          <Card variant="outlined" sx={{ mt: 2, mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {`Project Manager Review:`}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <SignatureSection
                  type="Project Manager"
                  signature={formState.projectManagerSignature}
                  onSignatureComplete={handleSignatureComplete}
                  onClearSignature={handleClearSignature}
                  readonly={mode !== 'reviewPM'}
                />
              </Box>
              <Box>
                <ReviewSection
                  reviewStatus={formState.projectManagerApprovalStatus === 'Project Manager Approved' ? 'Approved' : formState.projectManagerApprovalStatus === 'Project Manager Declined' ? 'Declined' : ''}
                  comments={formState.projectManagerComments}
                  reviewType="Project Manager"
                  onChangeStatus={handleStatusChange}
                  onChangeComments={handleCommentChange}
                />
              </Box>
            </CardContent>
          </Card>
        )}
        {mode === 'adminApproval' && (
          <Card variant="outlined" sx={{ mt: 2, mb: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {`Admin Review:`}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <SignatureSection
                  type="Admin"
                  signature={formState.adminSignature}
                  onSignatureComplete={handleSignatureComplete}
                  onClearSignature={handleClearSignature}
                  readonly={mode !== 'adminApproval'}
                />
              </Box>
              <Box>
                <ReviewSection
                  reviewStatus={formState.adminApprovalStatus === 'Admin Approved' ? 'Approved' : formState.adminApprovalStatus === 'Admin Declined' ? 'Declined' : ''}
                  comments={formState.adminApprovalComments}
                  reviewType="Admin"
                  onChangeStatus={handleStatusChange}
                  onChangeComments={handleCommentChange}
                />

              </Box>
            </CardContent>
          </Card>
        )}
      </DialogContent>
      <DialogActions>
        {mode !== 'reviewPM' && mode !== 'adminApproval' && mode !== 'review' && (
          <Button variant="contained" onClick={handleSubmit} color="primary">
            Save
          </Button>
        )}
        {mode === 'review' && (
          <Button variant="contained" onClick={handleReviewSubmit} color="primary" disabled={!validateForm()}>
            Submit SL Review
          </Button>
        )}
        {mode === 'reviewPM' && (
          <Button variant="contained" onClick={handleReviewSubmit} color="primary" disabled={!validateForm()}>
            Submit PM Review
          </Button>
        )}
        {mode === 'adminApproval' && (
          <Button variant="contained" onClick={handleReviewSubmit} color="primary" disabled={!validateForm()}>
            Submit Admin Review
          </Button>
        )}
        <Button variant="contained" onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>{snackbarMessage}</Alert>
      </Snackbar>
    </Dialog>
  );
};

export default InspectionForm;
