import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Box, Modal } from '@mui/material';
import PMApprovalModal from './PMApprovalModal';
import Vehicle from '../../Models/Vehicle';
import Project from '../../Models/Project';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


// Modal style, if using MUI Modal directly
const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

type ProjectManagerApprovalTableProps = {
    project: Project;
    onBack: () => void;
    vehicles: Vehicle[];
    onRefresh: () => void;
};

const ProjectManagerApprovalTable: React.FC<ProjectManagerApprovalTableProps> = ({ project, vehicles, onBack, onRefresh }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);

    const handleOpenModal = (vehicle: Vehicle) => {
        setSelectedVehicle(vehicle);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedVehicle(null);
        onRefresh();
    };

    //only use vehicles that are approved by site lead in table
    var vehicles1 = vehicles.filter(vehicle => vehicle.status === 'Site Lead Approved' || vehicle.status === 'Admin Declined');
    return (
        <>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'end' }}>
                <Button
                    variant="contained"
                    sx={{
                        bgcolor: 'primary.main', // Use theme primary color
                        color: 'white', // White text for contrast
                        textTransform: 'none', // Avoid uppercase text transformation
                        fontSize: '1rem', // Slightly larger font size
                        padding: '6px 24px', // Custom padding for a larger click area
                        borderRadius: '20px', // Rounded corners for a modern look
                        boxShadow: '0 3px 5px 2px rgba(25, 118, 210, .3)', // Subtle shadow for depth
                        '&:hover': {
                            bgcolor: 'primary.dark', // Darken button on hover for feedback
                            boxShadow: '0 5px 5px 3px rgba(25, 118, 210, .4)', // Enhanced shadow on hover
                        },
                    }}
                    onClick={onBack}
                    startIcon={<ArrowBackIosNewIcon />} // Consider adding an icon for visual guidance
                >
                    Back to Project List
                </Button>
            </Box>
            <TableContainer component={Paper} elevation={4} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead sx={{ bgcolor: 'primary.main' }}>
                        <TableRow>
                            <TableCell sx={{ color: 'common.white', textTransform: 'uppercase' }}>Vehicle Number</TableCell>
                            <TableCell sx={{ color: 'common.white', textTransform: 'uppercase' }}>Status</TableCell>
                            <TableCell sx={{ color: 'common.white', textTransform: 'uppercase' }}>Location</TableCell>
                            <TableCell sx={{ color: 'common.white', textTransform: 'uppercase' }}>Last Updated</TableCell>
                            <TableCell sx={{ color: 'common.white', textTransform: 'uppercase' }}>Review Vehicle</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vehicles1.map((vehicle) => (
                            <TableRow key={vehicle.vehicleId}>
                                <TableCell>{vehicle.vehicleNumber}</TableCell>
                                <TableCell>{vehicle.status}</TableCell>
                                <TableCell>{project.location}</TableCell>
                                <TableCell>{vehicle.updatedAt ? new Date(vehicle.updatedAt).toLocaleDateString() : ''}</TableCell>
                                <TableCell>
                                    <Button variant="outlined" color="primary" onClick={() => handleOpenModal(vehicle)}>
                                        Details
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedVehicle && (
                <PMApprovalModal
                    vehicle={selectedVehicle}
                    project={project}
                    onClose={handleCloseModal}
                    onRefresh={onRefresh}
                />
            )}
        </>
    );
};

export default ProjectManagerApprovalTable;
