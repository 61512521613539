import React, { useState, useCallback } from 'react';
import { Box, Typography, TextField, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

interface DiscrepancySectionProps {
  discrepancyDescription: string;
  discrepancyImagePaths: string[];
  onDescriptionChange: (description: string) => void;
  onImageAdd: (formData: FormData) => Promise<string[]>;
  onPathImageRemove: (index: number) => void;
}

const DiscrepancySection: React.FC<DiscrepancySectionProps> = ({
  discrepancyDescription,
  discrepancyImagePaths,
  onDescriptionChange,
  onImageAdd,
  onPathImageRemove,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    if (files.length > 0) {
      const formData = new FormData();
      files.forEach((file) => formData.append('files', file));

      try {
        const signedUrls = await onImageAdd(formData);
        event.target.value = ''; // Clear the input after upload
        setSelectedImage(null); // Reset the selected image
      } catch (error) {
        console.error('Image upload failed', error);
      }
    }
  };

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setOpenModal(true);
  };

  // Use useCallback for the description change handler to prevent unnecessary re-renders
  const handleDescriptionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onDescriptionChange(e.target.value);
  }, [onDescriptionChange]);

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" gutterBottom>Discrepancies</Typography>
      <TextField
        label="Discrepancy Description"
        fullWidth
        multiline
        rows={4}
        value={discrepancyDescription}
        onChange={handleDescriptionChange} // Using the memoized handler
        margin="normal"
      />
      <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="discrepancy-image-input"
          type="file"
          onChange={handleImageUpload}
          multiple
        />
        <label htmlFor="discrepancy-image-input">
          <IconButton color="primary" component="span">
            <PhotoCamera />
            <Typography variant="body2" style={{ color: 'black', fontWeight: 'bold' }} sx={{ ml: 1 }}>Upload Discrepancies</Typography>
          </IconButton>
        </label>
      </Box>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        mt: 2,
        justifyContent: 'flex-start',
        border: '1px dashed #ccc',
        padding: 2,
        borderRadius: '4px',
      }}>
        {discrepancyImagePaths.map((path, index) => (
          <Box key={index} sx={{ position: 'relative', width: 100, height: 100, overflow: 'hidden' }}>
            <img
              src={path}
              alt={`Discrepancy ${index + 1}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
              onClick={() => handleImageClick(path)}
            />
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onPathImageRemove(index);
              }}
              size="small"
              sx={{ position: 'absolute', top: 0, right: 0, color: 'error.main' }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
        ))}
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ outline: 'none', maxWidth: '90vw', maxHeight: '90vh', overflow: 'auto' }}>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Enlarged discrepancy"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default DiscrepancySection;
